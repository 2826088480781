.width_100{
    width: 100%;
    flex-wrap: unset;
    margin-top: 185px;
    
    
}
.width_100 .MuiGrid-root {
    width: 100%;
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 15px;
    overflow-x: hidden;
}
.width_100 .details-top{
    flex: 3;
}
.width_100 .MuiGrid-root .MuiPaper-rounded {
    height: 100%;
}
.width_100 .MuiGrid-root .MuiPaper-rounded .MuiCardContent-root{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    height: 100%;
}
.width_100 .MuiGrid-root .MuiPaper-rounded .MuiCardContent-root h6:last-child{
    margin-top: auto;
}
.widthdashtable{
    width:100%;
    min-height: 385px!important;
    max-height: 385px;
    overflow-x: auto;
    overflow-y: auto;
}
