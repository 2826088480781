.modalpos{
    top: 30% !important;
    z-index: 100;
    border:3px solid #dee2e6!important;
    border-radius: 15px;
    background: #ffffff;
    
}
.modal{
     top: 30% !important;
}
.boxtext{
    border-width: 5px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}