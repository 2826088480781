.otpbox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-height: calc(100vh - 750px);
    height: 100%;   
}

.card{
    padding: 2rem;
    box-shadow: 1px 1px 3px 0 #d4d4d5;
    border-radius: 4px;
    background: white;
}
.card > form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.margin-top--small{
    margin-top: 1rem;
    margin-bottom: -1rem;
}

.margin-top--small > div  {
    margin-bottom: 1rem;
}
.inputstyle{
    width: 2rem  ;
    height: 3rem;
    margin: 0 0.2rem;
    font-size: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.error{
    border: 1px solid red !important;
}

@media (min-width:768px) {
    .inputstyle{
        margin: 0 1rem;
        width: 3rem !important; 
    }
    .card{
        min-width: 700px!important;
    }
}