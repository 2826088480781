.record{
    background: #e59603!important;
    color: white!important;
}
.total{
    background: #6a7aee!important;
    color: white!important;
}
.card1{
    background: #57a1a1!important;
    color: white!important;
}
.card2{
    background: #17af3e!important;
    color: white!important;
}
.card3{
    background: #ff0000b3!important;
    color: white!important;
}