
.menutext{
    color: white !important;
    text-decoration: none !important;
    font-weight:500!important ;
    margin: 15px;
}
.menutext:hover{
    color: #000000  !important;
    
}
.active{
    color:  #ffc107  !important;
    text-decoration: none !important;
    font-weight:500!important ;
    margin: 15px;
    
    
}
.menubutton{
    color: #000000  !important;
    text-decoration: none;
    font-weight:500!important ;
}
.menubutton:hover{
    color: rgb(2, 2, 2) !important;
    background-color: #ffc107!important;
}
