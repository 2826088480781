.position{
    display: flex;
    height: calc(100vh - 400px);
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    margin-left: 300px;
    
}
.loading{
    margin-block-start: 5px;
    margin-left: 10px;
}
.smallloader{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    
}