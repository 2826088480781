.customer-list-table .table-responsive{
    margin:5px;
    height: calc(100vh - 120px);
    position: relative;
}
.customer-list-table thead{
    position: relative;
    overflow-y: hidden;
  
}
.customer-list-table tbody{
    overflow: hidden;
   
}
.customer-list-table table.table thead {
    position: sticky;
    top: 0;
    background-color: #fff;
}
.customer-list-table table.table thead tr.text-center:after {
    content: "";
    border-bottom: 2px solid;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}

.customer-list-table .table-bordered>:not(caption)>*{
    border-width: 0;
}
.width12{
    width:10%!important;
}
.width88{
    width:100%!important;
    height:calc(100vh - 100px);
    overflow-y: auto;
}
.tablehead{
    text-align: center;
    background: #6a7aee;
    color: white;
}

.pagecount{
    float: right;
    margin-block-start: 25px!important;
    margin-left: 40px!important;
}