.nav{
    background:linear-gradient(90deg, #11b232 0%, #6e78f7 100%);
    z-index: 9;
    width: 100%;
    max-width: 100%;

}


.main{
    width: 100%;
    z-index: 999;
    top: 0;
    left:0;
    position:fixed;
    padding-left: 0!important;
    padding-right: 0!important;

}

@media (min-width:992px) {
    .canvas_button{
        visibility:hidden;
    } 
}