.sidenav{
    display: flex!important;
    justify-content: space-between!important;
    overflow: none;
    margin-top: -10px;
    background: #4c4e7b;
    padding: 1px!important;
    align-items: left;
    text-align: start;
}
.text{ 
        display: block;
        text-align: left;
        color:rgb(255, 255, 255);
        font-size: 16px;
        text-decoration: none !important;
        padding: 10px!important; 
        border-radius: 10px;
        font-weight: 500;
}
.nav-item{
    display: block;
    margin: 1px;
    padding: 1px!important;
    font-size: 16px;
    text-decoration: none !important;
    font-weight: 500;  
}

.text:hover{
    color: black !important;
    background-color: white;
    border-radius: 10px;
}


.selected{
    display: block;
    margin: 3px;
    color: black !important;
    background-color: #ffc107 !important ;
    text-decoration: none !important;
    padding: 7px; 
    font-size: 18px;
    border-radius: 10px;
    font-weight: 500;
    

}



